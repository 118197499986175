import React, { useState } from 'react';
import moment from 'moment';
import css from "./Comment.module.css";

const RecentComments = props => {
  const { allComments, setAllComments, repliedOn, setRepliedOn, currentUser, allUsers } = props;
  const [showReplies, setShowReplies] = useState({});
  //to format date accordingly
  const formatDate = (dateTime)=>{
    let timeNow = new Date;
    var diff = moment(timeNow).diff(dateTime, 'days')
    if(diff > 7){
      return moment(dateTime).format('MM/DD/YYYY')
    }else{
      return `${moment(dateTime).fromNow(true)} ago`
    }
  }

  return (
    <div className={css.recentCommentsContainer}>
      {allComments?.length === 0 && <h4>No comments yet!</h4>}
      {allComments?.map((comment, commentIndex) => {
        let user = allUsers?.find(u => u.id.uuid == comment?.userId)?.attributes;
        return (
          <div
            className={css.comment_list_wrapper}
            key={`comments_${commentIndex}`}
          >
            <div className={css.avatarImg}>
              {comment?.userId === currentUser?.id?.uuid ? (
                user?.profile?.abbreviatedName
              ) : (
                <>{user?.profile?.abbreviatedName}</>
              )}
            </div>
            <div className={css.commentWrapper}>
              <span className={css.userNameWrapper}>
                {comment?.userId === currentUser?.id?.uuid ? 'You' : user?.email || "Anonymous"}
              </span>
              <span className={css.commentBody}>{comment?.comment}</span>
              <div className={css.commentActionButtons}>
                <span className={css.dateCreated}>{formatDate(comment?.postedon)}</span>
                <span
                  className={css.replyButton}
                  onClick={() => setRepliedOn({ index: commentIndex, userEmail: user.email })}
                >
                  Reply
                </span>
                {comment?.replies?.length > 0 && (
                  <span
                    className={css.replyButton}
                    onClick={() =>
                      setShowReplies({ ...showReplies, [commentIndex]: !showReplies[commentIndex] })
                    }
                  >
                    {showReplies[commentIndex] ? 'Hide ' : 'Show '}
                    replies
                  </span>
                )}
              </div>
              {showReplies[commentIndex] && (
                <div className={css.repliesWrapper}>
                  {comment?.replies?.map((reply, replyIndex) => {
                    let repUser = allUsers?.find(u => u.id.uuid == comment?.userId)?.attributes;
                    return (
                      <div
                        className={css.comment_list_wrapper}
                        key={`${commentIndex}_resplies_${replyIndex}`}
                      >
                        <div className={css.avatarImg}>{repUser?.profile?.abbreviatedName}</div>
                        <div>
                          <span className={css.userNameWrapper}>
                            {reply?.userId === currentUser?.id?.uuid ? 'You' : repUser?.email}
                          </span>
                          <span className={css.commentBody}>{reply?.comment}</span>
                          <div className={css.commentActionButtons}>
                            <span className={css.dateCreated}>{formatDate(reply?.postedon)}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RecentComments;
