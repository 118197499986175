import React, { useState, useEffect } from 'react';
import RecentComments from './RecentComments';
import css from './Comment.module.css';
import { addCommentToListing } from '../ListingPage.duck';
import { Heading } from '../../../components';
import lisingcss from '../ListingPage.module.css';
import { fetchQueryUsers } from '../../../ducks/user.duck';
import classNames from 'classnames';

const Comments = props => {
  const { currentListing, currentUser } = props;
  const listingId = currentListing?.id?.uuid;
  const [allComments, setAllComments] = useState([]);
  const [repliedOn, setRepliedOn] = useState();
  const [allUsers, setAllUsers] = useState([]);

  const fetchCommentsUser = async allcomments => {
    let userIds = allcomments?.map(c => c.userId);
    //get uniq values
    userIds = userIds?.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    fetchQueryUsers({ query: { key: 'pub_userId', value: userIds?.join(',') } }).then(r => {
      setAllUsers(r?.users?.data || []);
    });
  };

  const initComments = () => {
    try {
      const stringifiedComments = currentListing?.attributes?.publicData?.comments;
      if (stringifiedComments) {
        let comments = JSON.parse(stringifiedComments);
        if (allUsers.length <= 0) {
          fetchCommentsUser(comments);
        }
        setAllComments([...comments]);
      }
    } catch (e) {
      console.log('No comments yet!');
    }
  };

  useEffect(() => {
    initComments();
  }, [currentListing]);

  const [currentComment, setCurrentComment] = useState('');

  const addComment = () => {
    let allcomments = allComments;
    let currentcomment = {
      userId: currentUser?.id?.uuid,
      comment: currentComment,
      postedon: new Date(),
    };
    if (repliedOn) {
      allcomments[repliedOn?.index].replies ||= [];
      allcomments[repliedOn?.index].replies.push(currentcomment);
    } else {
      allcomments.push(currentcomment);
    }
    setRepliedOn();
    addCommentToListing({
      listingId: listingId,
      comments: allcomments,
      listing_url: window.location.href,
      listing_title: currentListing?.attributes?.title,
      authorId: currentListing?.author?.id?.uuid,
      userEmail: currentUser?.attributes?.profile?.displayName
    }).then(r => {
      if (r.success) {
        setCurrentComment('');
        setAllComments([...allcomments]);
      } else {
        console.log('Something went wrong ...');
      }
    });
  };

  const handleChange = e => {
    setCurrentComment(e.target.value);
  };
  return (
    <div className={css.comments_container}>
      <Heading as="h2" rootClassName={lisingcss.sectionHeadingWithExtraMargin}>
        Comments
      </Heading>
      <RecentComments
        {...{ allComments, setAllComments, repliedOn, setRepliedOn, currentUser, allUsers }}
      />
      <div className={css.postYourComment}>
        <div className={classNames(css.wFull, repliedOn ? css.replying_container : '')}>
          {repliedOn && (
            <div className={css.repliedOnSubmitWrapper}>
              <div className={css.replying_to_wrapper}>Replying to {repliedOn?.userEmail}</div>
              <span onClick={() => setRepliedOn()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </span>
            </div>
          )}
          <textarea
            placeholder="Add your comment"
            id="comment"
            rows={1}
            value={currentComment}
            onChange={e => handleChange(e)}
          />
        </div>

        <button className={css.send_comment} onClick={() => addComment()}>
          {repliedOn ? 'Reply' : 'Send'}
        </button>
      </div>
    </div>
  );
};

export default Comments;
